
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Watch} from 'vue-property-decorator';
    import {Route, NavigationGuardNext} from 'vue-router';
    import {namespace} from 'vuex-class';
    import Plausible from 'plausible-tracker';
    import {IOrganization, IUser} from '@/types';
    import {
        bannerHelper,
    } from '@/helpers';

    import BookingWidget from '@/components/BookingWidget.vue';
    import Breadcrumbs from '@/components/Breadcrumbs.vue';
    import OrganizationAddress from '@/components/organization/OrganizationAddress.vue';
    import OrganizationOpening from '@/components/organization/OrganizationOpening.vue';
    import OrganizationPricing from '@/components/organization/OrganizationPricing.vue';
    import OrganizationInfos from '@/components/organization/OrganizationInfos.vue';
    import OrganizationNewsCard from '@/components/OrganizationNewsCard.vue';

    const accountNamespace = namespace('account');

    @Component<ViewOrganization>({
        components: {
            BookingWidget,
            Breadcrumbs,
            OrganizationInfos,
            OrganizationNewsCard,
            OrganizationAddress,
            OrganizationOpening,
            OrganizationPricing,
        },
        beforeRouteEnter(to: Route, from: Route, next: any) {
            (Vue.prototype as Vue).$api.organization
                .getBySlug(`${to.params.type}/${to.params.city}/${to.params.slug}`)
                .then((organization: IOrganization) => {
                    next((vm: ViewOrganization) => vm.organization = organization);
                })
            ;
        },
        beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
            document.documentElement.style.overflow = 'auto';
            next();
        },
    })
    export default class ViewOrganization extends Vue {
        public showMap: boolean =  false;
        public showBookAppointment: boolean =  false;
        public bookingWidgetActive: boolean = false;
        public organization: IOrganization|null = null;
        public windowScroll!: number;
        public isScrolled: boolean = false;
        public dynaban: string = '';

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        get payments() {
            if (!this.organization) {
                return [];
            }

            if (!this.organization.payments) {
                return [];
            }

            return (this.organization.payments as string[]).map((payment) => {
                switch (payment) {
                    case 'american_express': return 'American Express';
                    case 'blue_card': return 'CB';
                    case 'cash': return 'Espèces';
                    case 'check': return 'Chèque';
                    default: return '';
                }
            });
        }

        get getVideoId() {
            const regExp: RegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            if (this.organization?.video) {
                const url: string = this.organization.video;
                const match = url.match(regExp);
                return (match && match[7].length === 11) ? match[7] : false;
            } else {
                return false;
            }
        }

        public handleTrackEvent() {
            const { trackEvent } = Plausible();
            const url = `https://www.chronovet.fr/?id_numcv=${this.organization?.social_links?.chronovet_id}&utm_source=banner&utm_medium=cta&utm_campaign=ope_vetolib`;

            trackEvent('chronovetBanner', {
                props: { method: 'HTTP', domain: 'vetolib.fr'},
            });

            window.open(url, '_blank');
        }

        public addWindowScrollListener() {
            window.addEventListener('scroll', () => {
                this.windowScroll = window.scrollY;

                const orgamenu: HTMLElement = this.$refs.organizationMenu as HTMLElement;

                if (orgamenu !== undefined) {
                    this.$nextTick(() => {
                        if ((orgamenu).offsetTop < this.windowScroll) {
                            this.isScrolled = true;
                        }

                        if (this.windowScroll < 330) {
                            this.isScrolled = false;
                        }
                    });
                }
            });
        }

        public mounted() {
            this.addWindowScrollListener();
        }

        public created() {
            this.dynaban = bannerHelper.getDynaBan();
        }

        @Watch('showMap')
        public onShowMapChange(val: boolean) {
            document.documentElement.style.overflow = val ? 'hidden' : 'auto';
        }

        @Watch('showBookAppointment')
        public onShowBookAppointmentChange(val: boolean) {
            document.documentElement.style.overflow = val ? 'hidden' : 'auto';
        }
    }
